<template>
  <div>
    <v-container>
      <!-- <h1>Agency: {{ $route.params.agency }}</h1>
      <h3>Person ID: {{ $route.params.id }}</h3> -->

      <v-row class="px-0 pa-sm-5">
        <v-col cols="12">
          <v-row>
            <v-col class="mt-5 mt-sm-0" cols="12">
              <v-card flat class="white">
                <v-card-title class="d-flex py-2">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <p class="my-0 ">Hi, Glyn!</p>
                      <p class="my-0 text-body-1 font-weight-light">You have some unfinished business</p>
                    </v-col>
                    <v-col class="d-flex justify-center align-center justify-sm-end" cols="12" sm="6">
                      {{ selectedPersonAccount.companyName }}
                      <!-- <div>
                        <v-img
                          max-height="60"
                          src="../../assets/logo-ipsum.svg">
                        </v-img>
                      </div> -->
                    </v-col>
                  </v-row>
                </v-card-title> 
              </v-card>
            </v-col>

            <v-col cols="12">
              <TenantAppPropertyInfoCard />
            </v-col>
            <!-- <v-col cols="12" lg="7">
              <TenantAppHomeWelcomeCard />
            </v-col> -->
            
            <v-btn @click="drawer = true" class="mx-auto mt-5" color="grey lighten-1" outlined x-large>Viewed properties</v-btn>


            <v-col v-if="tenancies" class="mt-12" cols="12">
              <div class="d-flex flex-wrap justify-space-around">

              </div>
              <!-- <v-btn color="secondary" depressed block height="50">Find new properties</v-btn> -->
            </v-col>
            

          </v-row>
        </v-col>
      </v-row>
      
    </v-container>
    
    <v-navigation-drawer
      absolute
      temporary
      right
      v-model="drawer"
      width="400"
    >
      <div v-if="tenancies" class="pt-3 d-flex flex-column align-center">
        <v-card
          v-for="(tenancy) in tenancies" :key="tenancy.OID" 
          flat
          outlined 
          class="mb-5 rounded-l"
        >
          <v-img height="150" max-width="300" src="../../assets/mock-property-img.jpg"></v-img>
          <v-card-text class="">
            <p class="text-center secondary--text text-h6 mb-0">{{ tenancy.Address.Address1 }}</p>
            <p class="my-0 text-center secondary--text font-weight-light">{{ tenancy.Address.Postcode }}</p>
          </v-card-text>
        </v-card>
      </div>
    </v-navigation-drawer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API  } from 'aws-amplify'

// import TenantAppHomeWelcomeCard from '@/components/tenantApp/homeCards/TenantAppHomeWelcomeCard.vue';
import TenantAppPropertyInfoCard from '@/components/tenantApp/homeCards/TenantAppPropertyInfoCard.vue';

export default {
  name: 'tenantHomepage',
  components: {
    // TenantAppHomeWelcomeCard,
    TenantAppPropertyInfoCard
  },
  data() {
    return {
      tenancies: '',
      drawer: false
    }
  },
  created() {
    this.getTenancies()
  },
  methods: {
    getTenancies() {
      const clientName = this.selectedPersonAccount.companyShortname
      const personOID = this.selectedPersonAccount.personOID
      API.get('agentSignMainRest', `/get?query=/${clientName}/${personOID}/tenancies`)
        .then(res => {
          console.log(res)
          this.tenancies = res.data.Data
        })
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount
    })
  }
}
</script>

<style scoped>

</style>