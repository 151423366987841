<template>
  <div>
    <v-card flat class="white"> 
      <v-card-text class="pa-5">
        <v-row class="top-row">
          <v-col class="left-col pa-0" cols="12" md="7">
            <v-row class="pa-0">
              <v-col class="d-flex d-md-none secondary py-1 flex-column align-start" cols="12">
                <span class="white--text text-h5 font-weight-medium">£675.00 pcm</span>
                <span class="white--text text-body-2 font-weight-medium">£168.75 pw</span>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters class="image-row">
                  <v-col class="d-flex align-center justify-center" cols="8">
                    <v-img  src="../../../assets/mock-property-img.jpg"></v-img>
                  </v-col>
                  <v-col class="pl-1 d-flex flex-column" cols="4">
                    <v-img class="mb-1" src="../../../assets/mock-property-img.jpg"></v-img>
                    <v-img src="../../../assets/mock-property-img.jpg"></v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="right-col" cols="12" md="5">
            <v-row>
              <v-col cols="12" class="pt-1 d-flex align-center justify-space-between">
                <div>
                  <p class="text-h6 my-0 font-weight-medium">20 Hunter Street</p>
                  <p class="text-body-2 mb-0 my-0">Cardiff, CF24 4HL</p>
                </div>
                <v-spacer></v-spacer>
                <v-btn 
                  href="https://www.google.com/maps/place/Moorhead+Cl,+Cardiff+CF24+5FD,+UK/@51.4776438,-3.1554568,17z/data=!3m1!4b1!4m5!3m4!1s0x486e1cd3d484c61b:0xfc15719909dfb1f0!8m2!3d51.4776438!4d-3.1532681" 
                  target="_blank"
                  fab
                  small
                  outlined 
                  color="primary"
                >
                  <v-icon>mdi-google-maps</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-2"></v-divider>
            <v-row class="mt-3 mt-sm-5" no-gutters>
              <v-col 
                class="" 
                cols="6"
                sm="4" 
                v-for="info in propertyInfo" :key="info.title"
              >
                <p class="text-caption mb-0 font-weight-light">{{ info.title }}</p>
                <p class="text-h6 text-md-body-1 font-weight-regular" :class="phoneColor(info)">{{ info.details }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-4 bottom-row" align="center">
          <v-col class="d-none d-md-flex secondary py-1 flex-column align-start" cols="12" md="7">
            <span class="white--text text-h5 font-weight-medium">£675.00 pcm</span>
            <span class="white--text text-body-2 font-weight-medium">£168.75 pw</span>
          </v-col>
          <v-col class="d-flex justify-end py-0" cols="12" md="5">
            <v-btn block color="primary" depressed height="60">
              <span class="text-h6">Book viewing</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'TenantAppPropertyInfoCard',
  data() {
    return {
      propertyInfo: [
        // { title: 'Rent', details: '£675.00 pcm'},
        { title: 'Term', details: '24 months'},
        { title: 'Start Date', details: '11/11/2020'},
        // { title: 'End Date', details: '11/11/2022'},
        { title: 'Holding Deposit', details: '£100'},
        { title: 'Security Deposit', details: '-£1,100'},
        { title: 'Agent', details: 'Pinnacle'},
        { title: 'Phone', details: '555-333-555-44'},
      ],
    }
  },
  methods: {
    phoneColor(info) {
      if(info.title === 'Phone') {
        return 'primary--text'
      }
    },
  }
}
</script>

<style>

</style>